import * as styles from './CubeWillOutliveMagicPlaymatConfirmation.module.css'

import React from 'react'

import CubeConPrintImage from './images/cube-will-outlive-magic-playmat-detail-3.jpg'
import * as Typography from '../typography'

const CubeWillOutliveMagicPlaymatConfirmation: React.FC = () => {
  return (
    <div className={styles.container}>
      <div>
        <Typography.PrimaryHeading>
          Thank you for your order!
        </Typography.PrimaryHeading>
        <Typography.Paragraph>
          You&rsquo;ll see a charge to &lsquo;Lucky Paper&rsquo; on your
          statement. Preorders are going to be open until April 19th. Our vendor
          has estimated a turnaround time of one month for production, plus
          additional time for packing and shipping. We always like to
          underpromise and overdeliver, so plan on your order shipping to you
          sometime in June.
        </Typography.Paragraph>
        <Typography.Paragraph>
          We will provide updates over email as we have them — thank you for
          your support! If you have any questions or concerns about your order
          please email us at{' '}
          <a href="mailto:mail@luckypaper.co">mail@luckypaper.co</a>.
        </Typography.Paragraph>
      </div>
      <img
        className={styles.image}
        src={CubeConPrintImage}
        alt="Cube Con 2023 Cube Map Print — Full Size"
      />
    </div>
  )
}

export default CubeWillOutliveMagicPlaymatConfirmation
